<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-details-apps ml-3 mr-3 pl-8 pr-8" v-if="!isMobile">
      <v-row>
        <v-col class="mt-3 pl-0 pr-0" cols="12">
          <v-img src="../../assets/img/app-details.png">
            <p class="text-title-ligth text-left ml-8" style="padding-top: 13%">
              {{ info.nombre_app }}
            </p>
          </v-img>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="9">
          <v-row align="center">
            <v-col cols="12">
              <p class="text-title-xl mt-6 mb-8 v-step-13">
                <strong>Descripción</strong>
              </p>
              <p class="text-left">
                {{ info.descripcion }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col style="text-align: -webkit-center" cols="3">
          <v-img width="200" :src="info.logo" contain>
            <v-btn
              @click="irApp(info)"
              style="margin-top: 80%"
              rounded
              dark
              class="btn-main v-step-14"
            >
              Ir a la aplicación
            </v-btn>
          </v-img>
        </v-col>
      </v-row>
    </div>
    <!-- MOBILE -->
    <div class="mb-sm-details-apps" v-else>
      <div>
        <v-card height="350px" color="#2e3e75">
          <v-btn @click="$router.go(-1)" x-small class="mt-7 mb-2" fab icon>
            <img
              class="ml-1"
              width="25"
              src="../../assets/icon/navigate_before_while.png"
            />
          </v-btn>
          <v-card-text class="pt-3" style="text-align: -webkit-center">
            <v-img width="200" :src="info.logo"> </v-img>
          </v-card-text>
        </v-card>
      </div>
      <div style="position: relative; bottom: 30px" class="pb-10 mb-10">
        <v-card style="border-radius: 15px">
          <v-card-title class="text-left"> {{ info.nombre_app }} </v-card-title>
          <v-card-text>
            <p class="text-left v-step-mobile-9">{{ info.descripcion }}</p>
            <p class="text-title-xl mt-4 mb-8 v-step-mobile-10">Tutorial</p>
            <p class="text-left">
              <hooper class="hooper-app" v-if="listTutorial.length > 0">
                <slide v-for="data in listTutorial" :key="data.imagen_paso">
                  <v-row justify="center">
                    <v-col cols="5">
                      <v-img width="80" :src="data.imagen_paso"> </v-img>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="12">
                      <p class="text-blue-sub-asap mt-3">
                        Paso {{ data.step }}
                      </p>
                      <p>
                        {{
                          data.descripcion_paso != ""
                            ? data.descripcion_paso
                            : "tutorial no disponible"
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </slide>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>
              <hooper class="hooper-app" v-else>
                <slide>
                  <v-row>
                    <v-col cols="7">
                      <p>Proximamente tutorial disponible</p>
                    </v-col>
                  </v-row>
                </slide>
              </hooper>
            </p>
          </v-card-text>
          <v-card-actions class="pb-10">
            <v-btn
              block
              @click="irApp(info)"
              rounded
              dark
              class="btn-main v-step-14 v-step-mobile-11"
            >
              Ir a {{ info.nombre_app }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/utils/api";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import moment from "moment";
import vueSignature from "vue-signature";
export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
    moment,
    vueSignature,
  },
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
        fontWeight: 0,
      },
      disabled: false,
      infoToken: {
        document: "",
      },
      listTutorial: [],
      app: {},
      name: this.$route.params.id,
      token: null,
      token64: null,
      info: {
        nombre_app: "",
        descripcion: "",
        logo: "",
        url: "",
        urlRedirect: "",
      },
      isMobile: false
    };
  },
  methods: {
    getDetailsApp() {
      Api.noAuth()
        .getDetailsApp(this.$route.params.id)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.data.length > 0) {
            this.listTutorial = [];
            this.info = data.data[0];
            var token = `?token=${this.token64}`;
            var url = data.data[0].url.replace("?token=", token);
            data.data[0].urlRedirect = url;
            if (this.info.pasos) {
              var keys = Object.keys(this.info.pasos);
              for (let i = 0; i < keys.length; i++) {
                this.listTutorial.push(this.info.pasos[keys[i]]);
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    async irApp(item) {
      const user = await sessionStorage.getItem("user_id");
      let dispositivo;
      if (screen.width < 1024) {
        dispositivo = "Mobile";
      } else {
        dispositivo = "Desktop";
      }
      const record = {
        userid: this.infoToken.document,
        appid: item._ID,
        appname: item.nombre_app,
        date: moment().format("YYYY-MM-DD"),
        dispositivo,
      };

      Api.noAuth()
        .saveAppActivity(record)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.success) {
            console.log("Se registro con exito");
          }
          var token = `?token=${this.token64}`;
          var url = item.url.replace("?token=", token);

          let newURL = document.createElement("a");
          newURL.id = "id-" + Math.floor(Math.random() * (9999999 - 500) + 500);
          newURL.href = url;
          newURL.target = "_blank";
          document.body.appendChild(newURL);
          newURL.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.token64 = localStorage.getItem("tokenB64");
    this.app = JSON.parse(localStorage.getItem("app"));
    this.getToken();
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  mounted() {
    this.getDetailsApp();
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/main";
.hooper-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  height: 100%;
  background: #f2f4ff;
  border: 1px solid #466be3;
  box-sizing: border-box;
  border-radius: 10px;
}

.mb-sm-details-apps {
  display: none;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-details-apps {
    display: none;
  }
  .mb-sm-details-apps {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-details-apps {
    display: none;
  }
  .mb-sm-details-apps {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-details-apps {
    display: none;
  }
  .mb-sm-details-apps {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-details-apps {
    display: none;
  }
  .mb-sm-details-apps {
    display: block;
    text-align: initial;
  }

  .container-img-app-sm {
    // width: 389px;
    height: 374px;
    left: 0px;
    top: 0px;
    background: #2e3e75;
  }
}
</style>
